import React, { useState, useEffect } from 'react';
import { Box, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '../components/Textfield';
import useCpiReport from '../hooks/useCpiReport';
import useWorkflow from '../hooks/useWorkflow';
import ClarinsCircularProgress from '../components/ClarinsCircularProgress';

export default function Login(props) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  let { isLoading, apiLogin, apiGetUserLogged, apiLogout } = useCpiReport();
  let { goNext } = useWorkflow();
  const history = useHistory();
  const [error, setError] = useState(false);

  const helperText = error ? 'Identifiant ou mot de passe incorrect.' : '';

  if (props.storybook) {
    ({ isLoading, apiLogin, apiGetUserLogged, apiLogout, goNext } = props);
  }

  useEffect(
    () => {
      const handleUserLogged = (user) => {
        if (user) {
          goNext();
        }
      };
      apiGetUserLogged(handleUserLogged);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // perform apiGetUserLogged only once at mount
  );

  useEffect(() => {
    if (history.action === 'POP') {
      apiLogout();
    }
  }, [history, apiLogout]);

  const onChangeLogin = (event) => {
    if (error) {
      setError(false);
    }
    setLogin(event.target.value);
  };
  const onChangePassword = (event) => {
    if (error) {
      setError(false);
    }
    setPassword(event.target.value);
  };
  const onChangeRememberMe = (event) => setRememberMe(event.target.checked);

  const handleLogin = (user) => {
    if (user) {
      goNext();
    } else {
      setError(true);
    }
  };
  const onClickNext = (e) => {
    apiLogin({ login, password, rememberMe }, handleLogin);
  };

  const args = {
    login,
    password,
    rememberMe,
    onChangeLogin,
    onChangePassword,
    onChangeRememberMe,
    onClickNext,
    error,
    helperText,
  };

  if (isLoading) {
    return <ClarinsCircularProgress />;
  }
  return <LoginComponent {...args} />;
}

export const LoginComponent = ({
  login,
  onChangeLogin,
  password,
  onChangePassword,
  rememberMe,
  onChangeRememberMe,
  onClickNext,
  error,
  helperText,
}) => {
  const tfWidth = '50vw';
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        height="80%"
      >
        <Box m={1} width={tfWidth}>
          <TextField
            label="Identifiant"
            value={login}
            onChange={onChangeLogin}
            error={error}
            fullWidth
          ></TextField>
        </Box>
        <Box m={1} width={tfWidth}>
          <TextField
            label="Mot de passe"
            value={password}
            onChange={onChangePassword}
            error={error}
            helperText={helperText}
            fullWidth
            type="password"
          ></TextField>
        </Box>
        <Box m={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={rememberMe}
                onChange={onChangeRememberMe}
              />
            }
            label="Rester connecté"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="20%"
      >
        <Button variant="contained" color="secondary" onClick={onClickNext}>
          CONTINUER
        </Button>
      </Box>
    </Box>
  );
};

LoginComponent.propTypes = {
  /**
   * Identifiant
   */
  login: PropTypes.string,
  /**
   * Mot de passe
   */
  password: PropTypes.string,
  /**
   * Rester connecté?
   */
  rememberMe: PropTypes.bool,
  /**
   * Erreur
   */
  error: PropTypes.bool,
  /**
   * Texte affiché en cas d'erreur
   */
  helperText: PropTypes.string,
  /**
   * Saisie de l'identifiant
   */
  onChangeLogin: PropTypes.func.isRequired,
  /**
   * Saisie du mot de passe
   */
  onChangePassword: PropTypes.func.isRequired,
  /**
   * Sélection mode de connection
   */
  onChangeRememberMe: PropTypes.func.isRequired,
  /**
   * Click sur le bouton continuer
   */
  onClickNext: PropTypes.func.isRequired,
};

LoginComponent.defaultProps = {
  login: '',
  password: '',
  rememberMe: false,
  error: false,
  helperText: null,
};
