import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import useWorkflow from '../hooks/useWorkflow';
import useCpiReport from '../hooks/useCpiReport';
import ClarinsCircularProgress from '../components/ClarinsCircularProgress';

export default function SearchStore(props) {
  const [query, setQuery] = useState('');
  let { goNext } = useWorkflow();

  let {
    isLoading,
    stores,
    apiGetSalesPoints,
    apiGetSalesPointsByPosition,
  } = useCpiReport();

  if (props.storybook) {
    ({
      isLoading,
      stores,
      apiGetSalesPoints,
      apiGetSalesPointsByPosition,
      goNext,
    } = props);
  }

  const onChangeQuery = (event) => {
    setQuery(event.target.value);
  };

  const onClickSearch = () => {
    apiGetSalesPoints(query);
  };

  const onClickGeoSearch = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        apiGetSalesPointsByPosition(position)
      );
    }
  };

  const onClickStore = function (store) {
    goNext({ id: store?.id_mag });
  };

  const args = {
    isLoading,
    query,
    onChangeQuery,
    onClickSearch,
    onClickGeoSearch,
    stores,
    onClickStore,
  };

  return <SearchStoreComponent {...args} />;
}

export const SearchStoreComponent = ({
  isLoading,
  query,
  onChangeQuery,
  onClickSearch,
  onClickGeoSearch,
  stores,
  onClickStore,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      maxHeight="100%"
      height="100%"
    >
      <Box mb={2}>
        <TextField
          id="search-store"
          label="Rechercher"
          value={query}
          onChange={onChangeQuery}
          helperText="Rue / Ville / Code postal / Enseigne"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickSearch}>
                  <SearchIcon />
                </IconButton>
                <IconButton onClick={onClickGeoSearch}>
                  <LocationSearchingIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isLoading && <ClarinsCircularProgress />}
      {!isLoading && (
        <Box width="100%" style={{ overflowY: 'auto' }}>
          {stores?.map(
            (store) =>
              (store?.nom_mag || store?.code_postal || store?.ville) && (
                <Box key={store?.id_mag}>
                  <Box
                    mb={1}
                    height={100}
                    onClick={() => onClickStore(store)}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="space-evenly"
                    width="100%"
                  >
                    <Box
                      fontFamily="GothamMedium"
                      maxHeight="1.5rem"
                      overflow="hidden"
                    >
                      {store?.nom_mag}
                    </Box>
                    <Box maxHeight="3rem" overflow="hidden">
                      {store?.adresse} {store?.adresse2}
                    </Box>
                    <Box maxHeight="1.5rem" overflow="hidden">
                      <Box display="inline">{store?.code_postal}</Box>{' '}
                      <Box display="inline">{store?.ville}</Box>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

SearchStoreComponent.propTypes = {
  /**
   * Requête
   */
  query: PropTypes.string,
  /**
   * Saisie de la requête
   */
  onChangeQuery: PropTypes.func.isRequired,
  /**
   * Clique sur le champs de recherche
   */
  onClickSearch: PropTypes.func.isRequired,
  /**
   * Clique sur le champs de recherche par géolocalisation
   */
  onClickGeoSearch: PropTypes.func.isRequired,
  /**
   * Résultat de la recherche
   */
  stores: PropTypes.array,
  /**
   * Sélection d'un magasin
   */
  onClickStore: PropTypes.func.isRequired,
};

SearchStoreComponent.defaultProps = {
  query: '',
  stores: [],
};
