import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useSnackbar } from "notistack";
import useSav from "../../hooks/useSav";
import useWorkflow from "../../hooks/useWorkflow";
import ClarinsCircularProgress from "../../components/ClarinsCircularProgress";

const Structure = ({ task }) => {
  return (
    <Box my={1}>
      <Box fontFamily="GothamMedium">
        {task?.detail?.structurel_sav_type_name}
      </Box>
      <Box>{task?.instruction?.comment || "\u00A0"}</Box>
    </Box>
  );
};

const Module = ({ task }) => {
  return (
    <Box my={1}>
      <Box>{task?.detail?.module_client_name}</Box>
      <Box>
        X{task?.qty} {task?.instruction?.module_instruction_name}
      </Box>
    </Box>
  );
};

const Task = ({ isLoading, task, index }) => {
  const { removeTask } = useSav();
  let Component = null;
  if (task.type.task_type_id === "7" || task.type.task_type_id === "9") {
    Component = Structure;
  } else if (task?.type?.task_type_id === "8") {
    Component = Module;
  }
  const handleRemove = () => removeTask(index);
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Component task={task}></Component>
        <IconButton size="small" onClick={handleRemove}>
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <Divider></Divider>
    </Box>
  );
};

export default function Tasks(props) {
  let { isLoading, sav, send: onClickCreateReport } = useSav();
  let { goNext, goStore: workflowGoStore } = useWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  if (props.storybook) {
    ({ isLoading, sav, goNext, workflowGoStore, onClickCreateReport } = props);
  }

  const onClickNewTask = () => {
    goNext();
  };

  useEffect(() => {
    if (sav.status === "server") {
      enqueueSnackbar("Demande enregistrée avec succès.", {
        variant: "success",
      });
      workflowGoStore();
    }
  }, [sav.status, workflowGoStore]);

  const args = {
    isLoading,
    sav,
    onClickNewTask,
    onClickCreateReport,
  };
  if (isLoading) {
    return <ClarinsCircularProgress />;
  }
  return <TasksComponent {...args} />;
}

export const TasksComponent = ({
  isLoading,
  sav,
  onClickNewTask,
  onClickCreateReport,
}) => {
  return (
    <Box
      color="text.primary"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="90%"
    >
      <Box width="100%" style={{ overflowY: "auto" }}>
        {sav?.tasks?.map((task, index) => (
          <Task key={index} task={task} index={index}></Task>
        ))}
      </Box>
      <Box my={2} width="70%">
        {sav?.status === "local" && isLoading === false && (
          <Button
            variant="contained"
            color="primary"
            onClick={onClickNewTask}
            fullWidth
          >
            NOUVELLE TÂCHE
          </Button>
        )}
      </Box>
      <Box my={2} width="70%">
        {sav?.status === "local" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickCreateReport}
            fullWidth
          >
            CRÉER LA DEMANDE
          </Button>
        )}
      </Box>
    </Box>
  );
};

TasksComponent.propTypes = {
  sav: PropTypes.object,
  onClickNewTask: PropTypes.func.isRequired,
  isValidated: PropTypes.bool,
  onClickCreateReport: PropTypes.func.isRequired,
};

TasksComponent.defaultProps = {
  sav: {},
  isValidated: false,
};
