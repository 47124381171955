import React, { useEffect } from "react";
import useCpiReport from "../../hooks/useCpiReport";
import ClarinsCircularProgress from "../ClarinsCircularProgress";

export default function ClarinsAppDatas({ children }) {
  const { isLoading, appDatas, getAppData } = useCpiReport();

  useEffect(() => {
    if (!appDatas) {
      getAppData();
    }
  }, [appDatas, getAppData]);

  if (isLoading) {
    return <ClarinsCircularProgress />;
  }
  if (!appDatas) {
    return <div>Erreur de téléchargement des données applicatives</div>;
  } else {
    return children;
  }
}
