import React, { useEffect } from "react";
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useHistory } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import useSession from "../../hooks/useSession";
import useWorkflow from "../../hooks/useWorkflow";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Box, Typography } from "@material-ui/core";
import styles from "./DefaultLayout.module.scss";
import GothamBook from "../../fonts/Gotham-Book.otf";
import GothamLight from "../../fonts/Gotham-Light.otf";
import GothamMedium from "../../fonts/Gotham-Medium.otf";
import ColorTheme from "./theme.json";

const gothamBook = {
  fontFamily: "GothamBook",
  src: `
    local('Gotham-Book'),
    url(${GothamBook}) format('truetype')
  `,
};
const gothamLight = {
  fontFamily: "GothamLight",
  src: `
    local('Gotham-Light'),
    url(${GothamLight}) format('truetype')
  `,
};
const gothamMedium = {
  fontFamily: "GothamMedium",
  src: `
    local('Gotham-Medium'),
    url(${GothamMedium}) format('truetype')
  `,
};

let theme = createMuiTheme({
  typography: {
    fontFamily: "GothamBook",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [gothamBook, gothamLight, gothamMedium],
      },
    },
  },
  ...ColorTheme,
});
theme = responsiveFontSizes(theme);

function BackDialog({ open, handleClose, handleYes, handleNo }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Annuler la dernière demande?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            Oui
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            Non
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ACTIONS = {
  BACK: "goBack",
  HOME: "goHome",
};

function Content({ children }) {
  const { goBack, goHome } = useWorkflow();
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const onGoBack = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
    switch (action) {
      case ACTIONS.BACK:
        goBack();
        break;
      case ACTIONS.HOME:
        goHome();
        break;
      default:
    }
  };

  const handleNo = () => {
    setOpen(false);
  };

  const onClickGoBack = () => {
    setAction(ACTIONS.BACK);
    goBack(onGoBack);
  };

  const onClickGoHome = () => {
    setAction(ACTIONS.HOME);
    goHome(onGoHome);
  };

  const onGoHome = () => {
    setOpen(true);
  };

  return (
    <Box className={styles.grid}>
      <Header onClickGoHome={onClickGoHome} />
      <Box px="4vw" py="2vh" width="100vw" maxHeight="100%" overflow="hidden">
        <Typography className={styles.typo} component="div">
          {children}
        </Typography>
        <BackDialog
          open={open}
          handleClose={handleClose}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      </Box>
      <Footer onClickGoBack={onClickGoBack} />
    </Box>
  );
}

export default function DefaultLayout(props) {
  const history = useHistory();
  const { sessionRestore } = useSession();

  useEffect(() => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    history.listen((location, action) => {
      if (action === "POP") {
        if (location?.state?.session) {
          sessionRestore(location.state.session);
        }
      }
    });
  }, [history, sessionRestore]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Content {...props} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
