import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/Login";
import SearchStore from "./pages/SearchStore";
import Store from "./pages/store/Store";
import InterventionsList from "./pages/store/InterventionsList";
import InterventionDetails from "./pages/store/InterventionDetails";
import Sav from "./pages/sav/Sav";
import Tasks from "./pages/sav/Tasks";
import SavStructurel from "./pages/sav/SavStructurel";
import SavModule from "./pages/sav/SavModule";
import SavModuleInstructions from "./pages/sav/SavModuleInstructions";
import DefaultLayout from "./layouts/DefaultLayout";
import SavLayout from "./layouts/SavLayout";
import Carousel from "./pages/store/Carousel";
import useSession from "./hooks/useSession";
import SessionContext from "./contexts/SessionContext";
import ClarinsAppDatas from "./components/ClarinsAppDatas";

function PrivateRoute({ children, ...rest }) {
  const { session } = useSession();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!session?.user) {
          return (
            <Redirect
              push
              to={{
                pathname: "/login",
                state: { from: location, session },
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
}

function App() {
  const [session, setSession] = useState({
    user: null,
    appDatas: null,
    store: null,
    photos: [],
    sav: { status: "local" },
  });

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      <Router>
        <DefaultLayout>
          <Switch>
            <Route path="/login">
              <Login></Login>
            </Route>
            <Route path="/error">Error</Route>
            <PrivateRoute exact path="/">
              <ClarinsAppDatas>
                <SearchStore></SearchStore>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/store">
              <ClarinsAppDatas>
                <SearchStore></SearchStore>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/store/:id">
              <ClarinsAppDatas>
                <Store></Store>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute path="/store/:id/carousel">
              <ClarinsAppDatas>
                <Carousel></Carousel>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/intervention">
              <ClarinsAppDatas>
                <InterventionsList></InterventionsList>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute path="/intervention/:id">
              <ClarinsAppDatas>
                <InterventionDetails></InterventionDetails>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/sav">
              <ClarinsAppDatas>
                <SavLayout>
                  <Sav></Sav>
                </SavLayout>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/sav/tasks">
              <ClarinsAppDatas>
                <SavLayout>
                  <Tasks></Tasks>
                </SavLayout>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/sav/SAV Structurel">
              <ClarinsAppDatas>
                <SavLayout>
                  <SavStructurel></SavStructurel>
                </SavLayout>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/sav/SAV Module">
              <ClarinsAppDatas>
                <SavLayout>
                  <SavModule></SavModule>
                </SavLayout>
              </ClarinsAppDatas>
            </PrivateRoute>
            <PrivateRoute exact path="/sav/SAV Module/instructions">
              <ClarinsAppDatas>
                <SavLayout>
                  <SavModuleInstructions></SavModuleInstructions>
                </SavLayout>
              </ClarinsAppDatas>
            </PrivateRoute>
          </Switch>
        </DefaultLayout>
      </Router>
    </SessionContext.Provider>
  );
}

export default App;
