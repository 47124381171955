import { useSnackbar } from "notistack";
import useLog from "../hooks/useLog";

export default function useErrors() {
  const { enqueueSnackbar } = useSnackbar();
  const { log } = useLog("useErrors");

  function onError(error) {
    log("%O %O", onError, arguments);
    let msg = "";
    switch (error?.response?.status) {
      case 401:
        msg = "Utilisateur non authentifié";
        break;
      case 403:
        msg = "Accès refusé";
        break;
      case 404:
        msg = "Page non trouvée";
        break;
      case 500:
        msg = "Erreur serveur";
        break;
      default:
        msg = "Erreur";
        break;
    }
    enqueueSnackbar(msg, {
      variant: "error",
    });
  }

  return {
    onError,
  };
}
