import useSession from '../hooks/useSession';
import useCpiReport from './useCpiReport';
import useLog from '../hooks/useLog';

export const savWorkflows = [
  {
    intervention_type_id: '6',
    task_type_id: '7',
    name: 'SAV STRUCTUREL MAQUILLAGE',
    route: '/sav/SAV Structurel',
  },
  {
    intervention_type_id: '9',
    task_type_id: '8',
    name: 'SAV MODULE MAQUILLAGE',
    route: '/sav/SAV Module',
  },
  {
    intervention_type_id: '7',
    task_type_id: '9',
    name: 'SAV STRUCTUREL SOIN',
    route: '/sav/SAV Structurel',
  },
];

// TODO: error management

export default function useSav() {
  const { isLoading, appDatas, sendSav } = useCpiReport();
  const { session, setSession, resetSav } = useSession();
  const { store, sav, photos } = session;
  const { log } = useLog('useSav');

  // Create current sav
  function create(interventionType, taskType) {
    log('%O %O', create, arguments);
    setSession((prevSess) => ({
      ...prevSess,
      sav: { ...sav, interventionType, taskType, store },
    }));
  }

  // Add task to tasklit
  const addTask = (task) => {
    log('%O %O', addTask, arguments);
    if (task.detail && task.instruction) {
      task.type = sav.taskType;
      const cloneSav = { ...sav };
      if (!cloneSav.tasks) {
        cloneSav.tasks = [];
      }
      cloneSav.tasks.push(task);
      setSession((prevSess) => ({
        ...prevSess,
        sav: cloneSav,
      }));
    } else {
      console.error('Invalid task detail or instruction', task);
    }
  };

  const addPhoto = (photo) => {
    log('%O %O', addPhoto, arguments);
    const clonePhoto = [...photos];
    clonePhoto.push(photo);
    setSession((prevSess) => ({
      ...prevSess,
      photos: clonePhoto,
    }));
  };

  const removeTask = (index) => {
    log('%O %O', removeTask, arguments);
    const cloneSav = { ...sav };
    cloneSav.tasks.splice(index, 1);
    setSession((prevSess) => ({ ...prevSess, sav: cloneSav }));
    const clonePhoto = [...photos];
    clonePhoto.splice(index, 1);
    setSession((prevSess) => ({ ...prevSess, photos: clonePhoto }));
  };

  const getNextRoute = () => {
    const workflow = savWorkflows.find(
      (workflow) => workflow?.task_type_id === sav?.taskType?.task_type_id
    );
    return workflow?.route;
  };

  const reset = () => {
    resetSav();
  };

  return {
    workflows: savWorkflows,
    interventionsTypes: appDatas?.intervention_type,
    taskTypes: appDatas?.task_type,
    structurelTypes: appDatas?.structurel_sav_type,
    moduleInstructions: appDatas?.module_instruction,
    isLoading,
    sav,
    create,
    addTask,
    removeTask,
    send: sendSav,
    addPhoto,
    getNextRoute,
    reset,
  };
}
