import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import SendIcon from "@material-ui/icons/Send";
import head from "lodash/head";
import ClarinsTextField from "../../../components/ClarinsTextField";
import useCpiReport from "../../../hooks/useCpiReport";
import useWorkflow from "../../../hooks/useWorkflow";
import styles from "./Store.module.scss";
import ClarinsImage from "../../../components/ClarinsImage";
import ClarinsCircularProgress from "../../../components/ClarinsCircularProgress";

const EmailDialog = ({ open, setOpen, sendMail }) => {
  const handleClose = (response) => {
    setOpen(false);
    sendMail(response);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Souhaitez vous mettre à jour l'email de contact du magasin?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} color="primary">
          Oui
        </Button>
        <Button onClick={() => handleClose(false)} color="primary">
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function Store(props) {
  let { goNext } = useWorkflow();
  let {
    isLoading,
    store,
    apiSendPlanoByEmail,
    apiGetSalesPointClarinsByID,
  } = useCpiReport();
  const [email, setEmail] = useState(store?.contact_email);
  const [isOpenEmailDialog, setIsOpenEmailDialog] = useState(false);
  const { id } = useParams();

  if (props.storybook) {
    ({ isLoading, store, apiSendPlanoByEmail, goNext } = props);
  }

  useEffect(() => {
    if (!store) {
      apiGetSalesPointClarinsByID(id);
    }
  }, [store, apiGetSalesPointClarinsByID, id]);

  const onClickInterventions = () => goNext({ path: "/intervention" });

  const onClickSav = () => goNext({ path: "/sav" });

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onClickEmail = () => {
    // TODO: Email validation
    if (email !== store?.contact_email) {
      setIsOpenEmailDialog(true);
    } else {
      onSendMail(false);
    }
  };

  const onSendMail = (updateEmail) => {
    apiSendPlanoByEmail(store.id_mag, email, updateEmail);
  };

  const onClickPlano = (url) => {
    window.open(url, "_blank");
  };

  const onClickCarousel = () => {
    goNext({ path: `/store/${store.id_mag}/carousel` });
  };

  const args = {
    store,
    onClickCarousel,
    onClickPlano,
    email,
    isOpenEmailDialog,
    setIsOpenEmailDialog,
    onSendMail,
    onChangeEmail,
    onClickEmail,
    onClickInterventions,
    onClickSav,
  };

  if (isLoading || !store) {
    return <ClarinsCircularProgress />;
  }
  return <StoreComponent {...args} />;
}

export const StoreComponent = ({
  store,
  onClickCarousel,
  onClickPlano,
  email,
  onChangeEmail,
  onClickEmail,
  isOpenEmailDialog,
  setIsOpenEmailDialog,
  onSendMail,
  onClickInterventions,
  onClickSav,
}) => {
  const meuble = head(store?.meubles);
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
    >
      <Box
        className={styles.picture}
        bgcolor="primary.light"
        onClick={onClickCarousel}
      >
        <ClarinsImage src={head(store?.photos)} alt=""></ClarinsImage>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        maxHeight="60%"
        style={{ overflowY: "auto" }}
      >
        <Box>
          <ClarinsTextField
            label="Code client"
            value={store?.code_pdv_enseigne}
          ></ClarinsTextField>
        </Box>
        <Box>
          <ClarinsTextField
            label="Code interne"
            value={store?.code_pdv}
          ></ClarinsTextField>
        </Box>
        <Box>
          <ClarinsTextField
            label="Enseigne"
            value={store?.enseigne}
          ></ClarinsTextField>
        </Box>
        <Box>
          <ClarinsTextField
            label="Nom"
            value={store?.nom_mag}
          ></ClarinsTextField>
        </Box>
        <Box>
          <ClarinsTextField
            style={{ width: "100%" }}
            label="Adresse"
            value={`${store?.adresse || ""} ${store?.adresse2 || ""}${
              store?.adresse || store?.adresse2 ? ", " : ""
            }${store?.code_postal || ""} ${store?.ville || ""}`}
          ></ClarinsTextField>
        </Box>
        <Box>
          {meuble?.meuble_serial && meuble?.meuble_type && (
            <ClarinsTextField
              label="Meuble"
              value={`${meuble?.meuble_serial} ${meuble?.meuble_type}`}
            ></ClarinsTextField>
          )}
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {meuble && (
          <Box
            my={0.5}
            width="100%"
            minHeight="50px"
            display="flex"
            justifyContent="center"
          >
            <Button
              variant="contained"
              startIcon={<ImageIcon />}
              onClick={() => onClickPlano(meuble?.planogram_path)}
            >
              Ouvrir le planogramme
            </Button>
          </Box>
        )}
        {meuble && (
          <Box
            mb={1}
            width="100%"
            minHeight="50px"
            display="flex"
            justifyContent="center"
          >
            <TextField
              label="Email"
              type="text"
              value={email}
              onChange={onChangeEmail}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={onClickEmail}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
        )}
        {meuble && (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignSelf="center"
            width="100%"
          >
            <Box width="40%" mr={2}>
              <Button
                variant="contained"
                onClick={onClickInterventions}
                color="primary"
                fullWidth
              >
                Liste des interventions
              </Button>
            </Box>
            <Box width="40%" ml={2}>
              <Button
                variant="contained"
                onClick={onClickSav}
                color="primary"
                fullWidth
              >
                Demande de SAV
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <EmailDialog
        open={isOpenEmailDialog}
        setOpen={setIsOpenEmailDialog}
        email={email}
        sendMail={onSendMail}
      />
    </Box>
  );
};

StoreComponent.propTypes = {
  /**
   * Magasin
   */
  store: PropTypes.object,
  /**
   * Clique sur la photo du magasin
   */
  onClickCarousel: PropTypes.func.isRequired,
  /**
   * Clique sur le planogramme
   */
  onClickPlano: PropTypes.func.isRequired,
  /**
   * Email
   */
  email: PropTypes.string,
  /**
   * Saisie d'Email
   */
  onChangeEmail: PropTypes.func.isRequired,
  /**
   * Clique sur l'envoi de mail
   */
  onClickEmail: PropTypes.func.isRequired,
  /**
   * Ouverture confirmation mise à jour de mail
   */
  isOpenEmailDialog: PropTypes.bool,
  /**
   * Modification valeur de isOpenEmailDialog
   */
  setIsOpenEmailDialog: PropTypes.func.isRequired,
  /**
   * Emvoi du planogramme par mail
   */
  onSendMail: PropTypes.func.isRequired,
  /**
   * Clique sur le bouton de visualisation de sinterventions
   */
  onClickInterventions: PropTypes.func.isRequired,
  /**
   * Déclaration d'un SAV
   */
  onClickSav: PropTypes.func.isRequired,
};

StoreComponent.defaultProps = {
  store: {},
  email: "",
  isOpenEmailDialog: false,
};
