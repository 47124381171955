import React, { Suspense } from "react";
import { useImage } from "react-image";
import defaultImage from "../../images/default-image.png";

function MyImageComponent({ src, ...props }) {
  const { src: source } = useImage({
    srcList: [src, defaultImage],
  });

  return <img src={source} alt="" {...props} />;
}

export default function ClarinsImage(props) {
  return (
    <Suspense fallback={<div>Chargement...</div>}>
      <MyImageComponent {...props} />
    </Suspense>
  );
}
