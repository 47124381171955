import React, { useState } from 'react';
import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Box } from '@material-ui/core';
import ImagePreview from '../ClarinsImagePreview';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& video,image': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '& .react-html5-camera-photo': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
});

const ClarinsPhoto = ({ dataUri, setDataUri, onDelete, ...props }) => {
  const [isError, setIsError] = useState();
  const classes = useStyles();

  function handleTakePhoto(dataUri) {
    setDataUri(dataUri);
  }

  const onCameraError = (error) => {
    setIsError(true);
  };

  const isFullscreen = false;
  if (!dataUri && isError) {
    return <Box>Erreur d'accès à la caméra</Box>;
  }
  return (
    <Box className={classes.root} {...props}>
      {dataUri ? (
        <ImagePreview
          dataUri={dataUri}
          setDataUri={setDataUri}
          isFullscreen={isFullscreen}
          onDelete={onDelete}
        />
      ) : (
        <Camera
          onTakePhoto={handleTakePhoto}
          onCameraError={onCameraError}
          isFullscreen={isFullscreen}
          imageType={IMAGE_TYPES.JPG}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 1920, height: 1440 }}
          isImageMirror={false}
        />
      )}
    </Box>
  );
};

export default React.memo(ClarinsPhoto);
