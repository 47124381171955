import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useSession from "../../hooks/useSession";
import ClarinsTextField from "../../components/ClarinsTextField";
import ClarinsModule from "../../components/ClarinsModule";
import ClarinsDate from "../../components/ClarinsDate";

const Module = ({ task }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      my={1}
    >
      <Box width="50%">
        <Box fontFamily="GothamMedium">
          {task?.task_module_instruction?.module_instruction_name}
        </Box>
      </Box>
      <Box width="50%">
        <ClarinsModule
          src={task?.task_type_detail?.module_client_picture}
          name={task?.task_type_detail?.module_client_name}
          width="100%"
          qty={task?.quantity}
        ></ClarinsModule>
      </Box>
    </Box>
  );
};

const Structure = ({ task }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    my={1}
  >
    <Box width="50%">
      <Box fontFamily="GothamMedium">
        {task?.task_type_detail?.structurel_sav_type_name}
      </Box>
      <Box>{task?.task_instruction}</Box>
    </Box>
    <Box width="50%">
      <ClarinsModule src={task?.task_picture} name=""></ClarinsModule>
    </Box>
  </Box>
);

const Task = ({ task }) => {
  let Component = null;
  if (task?.task_type_id === "8") {
    Component = Module;
  } else if (task?.task_type_id === "7" || task?.task_type_id === "9") {
    Component = Structure;
  }
  return (
    <Box>
      <Component task={task}></Component>
      <Divider></Divider>
    </Box>
  );
};

export default function InterventionDetails(props) {
  let { session } = useSession();
  let { id } = useParams();

  if (props.storybook) {
    ({ session, id } = props);
  }

  const intervention = session?.store?.interventions.find(
    (intervention) => intervention?.install_id === id
  );

  if (!intervention) {
    return null;
  }

  return <InterventionDetailsComponent intervention={intervention} />;
}

export const InterventionDetailsComponent = ({ intervention }) => {
  return (
    <Box overflow="hidden" height="100%">
      <Box
        height="33%"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ClarinsTextField
            label="Type d'intervention"
            value={intervention?.intervention_type_name}
          ></ClarinsTextField>
          <Box textAlign="right">
            <ClarinsTextField
              label="Identifiant"
              value={intervention?.install_id}
            ></ClarinsTextField>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ClarinsTextField
            label="Statut"
            value={intervention?.status}
          ></ClarinsTextField>
          <Box textAlign="right">
            <ClarinsTextField label="Date demandée">
              <ClarinsDate date={intervention?.date_created}></ClarinsDate>
            </ClarinsTextField>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ClarinsTextField
            label="Demandeur"
            value={intervention?.proprietaire}
          ></ClarinsTextField>
          <Box textAlign="right">
            <ClarinsTextField label="Date planifiée">
              <ClarinsDate date={intervention?.date_rdv}></ClarinsDate>
            </ClarinsTextField>
          </Box>
        </Box>
      </Box>
      <Divider></Divider>
      <Box height="66%" style={{ overflowY: "auto" }}>
        {intervention?.tasks?.map((task) => (
          <Task key={task?.task_report_id} task={task} />
        ))}
      </Box>
    </Box>
  );
};

InterventionDetailsComponent.propTypes = {
  /**
   * Intervention
   */
  intervention: PropTypes.object,
};

InterventionDetailsComponent.defaultProps = {
  intervention: {},
};
