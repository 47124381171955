import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import useCpiReport from "../../hooks/useCpiReport";
import useWorkflow from "../../hooks/useWorkflow";

const getTitle = (pathname) => {
  if (pathname.startsWith("/store")) {
    return "POINT DE VENTE";
  }
  if (pathname.startsWith("/intervention")) {
    return "POINT DE VENTE";
  }
  switch (pathname) {
    case "/login":
      return "AUTHENTIFICATION";
    case "/store":
    case "/intervention":
      return "POINT DE VENTE";
    case "/sav":
      return "SAV";
    default:
      return "ACCUEIL";
  }
};

export default function Header({ onClickGoHome }) {
  const location = useLocation();
  const { user, apiLogout } = useCpiReport();
  const { goNext } = useWorkflow();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const handleLogout = () => {
    setIsLoggingOut(true);
    apiLogout();
  };

  useEffect(() => {
    if (isLoggingOut && !user) {
      goNext({ path: "/" });
      setIsLoggingOut(false);
    }
  }, [user, goNext, isLoggingOut]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="primary.main"
      color="primary.contrastText"
    >
      <Box ml={2}>
        <IconButton color="inherit" size="small" onClick={onClickGoHome}>
          <HomeIcon />
        </IconButton>
      </Box>
      <Box fontFamily="GothamMedium">
        <Typography variant="h4">{getTitle(location.pathname)} </Typography>
      </Box>
      <Box mr={2}>
        <IconButton color="inherit" size="small" onClick={handleLogout}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
