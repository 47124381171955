import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import './styles/imagePreview.css';

const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};

export const ImagePreview = ({
  dataUri,
  setDataUri,
  isFullscreen,
  onDelete,
}) => {
  let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

  return (
    <Box
      className={'demo-image-preview ' + classNameFullscreen}
      width="100%"
      height="100%"
      position="relative"
      bgcolor="primary.light"
    >
      {onDelete && (
        <Box position="absolute" right={0} top={0}>
          <Fab color="primary" size="small" onClick={onDelete}>
            <DeleteIcon />
          </Fab>
        </Box>
      )}
      <img style={imgStyle} src={dataUri} alt="" />
    </Box>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool,
};

export default ImagePreview;
