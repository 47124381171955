import React from "react";
import { Box, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import styles from "./Footer.module.scss";
import { ReactComponent as ClarinsLogo } from "./clarins_logo.svg";

export default function Footer({ onClickGoBack }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={styles.footer}
      bgcolor="primary.main"
    >
      <Box color="common.white" position="absolute" left={0}>
        <IconButton color="inherit" size="medium" onClick={onClickGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
      </Box>
      <Box mx={2}>
        <ClarinsLogo className={styles.logo} />
      </Box>
    </Box>
  );
}
