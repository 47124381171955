import React, { useContext } from "react";
import SessionContext from "../contexts/SessionContext";
import useLog from "../hooks/useLog";

export default function useSession() {
  const { session, setSession } = useContext(SessionContext);
  const { log } = useLog("useSession");

  // reset SAV (go store)
  function sessionResetSav() {
    log("%O", sessionResetSav);

    setSession((prevSess) => ({
      ...prevSess,
      sav: { status: "local" },
      photos: [],
    }));
  }

  // reset Store And Sav (go home)
  function sessionInit() {
    log("%O", sessionInit);
    sessionResetSav();
    setSession((prevSess) => ({
      ...prevSess,
      store: null,
    }));
  }

  function sessionLogout() {
    log("%O", sessionLogout);
    sessionResetSav();
    sessionInit();
    setSession((prevSess) => ({
      ...prevSess,
      user: null,
    }));
  }

  function sessionRestore(state) {
    log("%O %O", sessionRestore, state);
    setSession(state);
  }

  return {
    session,
    setSession,
    sessionResetSav,
    sessionInit,
    sessionLogout,
    sessionRestore: React.useCallback(sessionRestore, []),
  };
}
