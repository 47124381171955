import debug from "debug";

// https://levelup.gitconnected.com/step-up-your-console-messaging-game-in-your-react-app-42eee17659ec
const BASE = "clarins-mobile-report";

const COLOURS = {
  useCpiReport: "yellow",
  useErrors: "red",
  useSav: "skyblue",
  useSession: "green",
  useWorkflow: "violet",
};

export default function useLog(module) {
  const namespace = `${BASE}:${module}`;
  const logger = debug(namespace);
  logger.color = COLOURS[module];

  const generateMessage = (formatter, args) => {
    logger(formatter, ...(args || ""));
  };

  const log = (formatter, ...args) => {
    return generateMessage(formatter, args);
  };

  return {
    log,
  };
}
