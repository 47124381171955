import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSav from "../hooks/useSav";
import useSession from "../hooks/useSession";
import useLog from "../hooks/useLog";

export default function useWorkflow() {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const { sav, getNextRoute } = useSav();
  const { log } = useLog("useWorkflow");
  const { session, sessionInit, sessionResetSav } = useSession();
  const { store } = session;

  function goNext({ id, path, state } = {}) {
    const { from } = state || { from: { pathname: "/" } };
    log("%O %O", goNext, arguments);
    if (path) {
      return history.push(path, { session });
    }
    switch (pathname) {
      case "/login":
        history.push(from.pathname, { session });
        break;
      case "/":
      case "/store":
        history.push(`/store/${id}`, { session });
        break;
      case "/intervention":
        history.push(`/intervention/${id}`, { session });
        break;
      case "/sav":
        // No task -> go to target
        history.push(getNextRoute(), { session });
        break;
      case "/sav/SAV Structurel":
        history.push(`/sav/tasks`, { session });
        break;
      case "/sav/SAV Module":
        if (state) {
          history.push({
            pathname: `/sav/SAV Module/instructions`,
            state: { session, state },
          });
        }
        break;
      case "/sav/SAV Module/instructions":
        history.push(`/sav/tasks`, { session });
        break;
      case "/sav/tasks":
        history.push(getNextRoute(), { session });
        break;
      default:
        history.push("/", { session });
    }
  }

  function goStore() {
    log("%O", goStore);
    sessionResetSav();
    history.push(`/store/${store.id}`, { session });
  }

  function goHome(onGoHome = null) {
    log("%O", goHome);
    if (pathname === "/sav/tasks" && onGoHome) {
      if (sav.tasks.length > 0) {
        onGoHome();
      } else {
        sessionInit();
        history.push(`/`, { session });
      }
    } else {
      sessionInit();
      history.push(`/`, { session });
    }
  }

  function goBack(onGoBack = null) {
    log("%O", goBack);
    if (pathname === "/sav/tasks" && onGoBack) {
      if (sav?.tasks?.length > 0) {
        onGoBack();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }

  return {
    goNext,
    goStore: useCallback(goStore, []),
    goHome,
    goBack,
    state,
  };
}
