import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import useWorkflow from '../../hooks/useWorkflow';
import useSav from '../../hooks/useSav';

export default function Sav(props) {
  let { workflows, interventionsTypes, taskTypes, create } = useSav();
  let { goNext } = useWorkflow();
  const [workflow, setWorkflow] = useState();

  if (props.storybook) {
    ({ workflows, interventionsTypes, taskTypes, create, goNext } = props);
  }

  // Go next when sav is updated after create call
  useEffect(() => {
    if (workflow) {
      goNext();
    }
  }, [goNext, workflow]);

  const onClickWorkflow = (workflow) => {
    const { intervention_type_id, task_type_id } = workflow;
    const interventionType = interventionsTypes.find(
      (intervention) =>
        intervention?.intervention_type_id === intervention_type_id
    );
    const taskType = taskTypes.find(
      (type) => type?.task_type_id === task_type_id
    );
    create(interventionType, taskType);
    setWorkflow(workflow);
  };

  const args = {
    workflows,
    onClickWorkflow,
  };

  return <SavComponent {...args} />;
}

export const SavComponent = ({ workflows, onClickWorkflow }) => {
  return (
    <Box
      color="text.primary"
      display="flex"
      flexDirection="column"
      height="90%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="60%"
        justifyContent="space-between"
        alignItems="center"
        width="50%"
      >
        {workflows?.map((workflow) => (
          <Button
            key={workflow.name}
            variant="contained"
            color="primary"
            onClick={() => onClickWorkflow(workflow)}
          >
            {workflow?.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

SavComponent.propTypes = {
  /**
   * Workflows definitions (cf. useSav)
   */
  workflows: PropTypes.array,
  /**
   * Sélection d'un workflow
   */
  onClickWorkflow: PropTypes.func,
};

SavComponent.defaultProps = {
  workflows: [],
  onClickWorkflow: () => {},
};
