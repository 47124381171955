import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import useWorkflow from '../../hooks/useWorkflow';
import useSav from '../../hooks/useSav';

export default function SavModuleInstructions(props) {
  let { state, goNext } = useWorkflow();
  let { moduleInstructions, addTask } = useSav();
  const [instructionId, setInstructionId] = useState();
  const [qty, setQty] = useState('1');
  const [error, setError] = useState(false);
  let module = state?.state?.module;

  if (props.storybook) {
    ({ moduleInstructions, addTask, module, goNext } = props);
  }

  const onChangeInstruction = (event) => {
    setInstructionId(event.target.value);
  };

  const onChangeQty = (event) => {
    setQty(event.target.value);
  };

  const onClickValidate = () => {
    if (instructionId) {
      const instruction = moduleInstructions.find(
        (i) => i?.module_instruction_id === instructionId
      );
      addTask({ detail: module, instruction, qty });
      goNext();
    } else {
      setError(true);
    }
  };

  const args = {
    moduleInstructions,
    instructionId,
    onChangeInstruction,
    qty,
    onChangeQty,
    onClickValidate,
    error,
  };
  return <SavModuleInstructionsComponent {...args} />;
}

export const SavModuleInstructionsComponent = ({
  moduleInstructions,
  instructionId,
  onChangeInstruction,
  qty,
  onChangeQty,
  onClickValidate,
  error,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly"
      height="90%"
    >
      <Box width="50%" my={2}>
        <FormControl fullWidth error={error}>
          <InputLabel>Type</InputLabel>
          <Select value={instructionId} onChange={onChangeInstruction}>
            {moduleInstructions?.map((i) => (
              <MenuItem
                key={i?.module_instruction_id}
                value={i?.module_instruction_id}
              >
                {i?.module_instruction_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width="50%" my={2}>
        <FormControl fullWidth>
          <InputLabel>Quantité</InputLabel>
          <Select value={qty} onChange={onChangeQty}>
            <MenuItem value="1">{1}</MenuItem>
            <MenuItem value="2">{2}</MenuItem>
            <MenuItem value="3">{3}</MenuItem>
            <MenuItem value="4">{4}</MenuItem>
            <MenuItem value="5">{5}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box my={2}>
        <Button variant="contained" color="primary" onClick={onClickValidate}>
          VALIDER
        </Button>
      </Box>
    </Box>
  );
};

SavModuleInstructionsComponent.propTypes = {
  moduleInstructions: PropTypes.array,
  instructionId: PropTypes.string,
  onChangeInstruction: PropTypes.func.isRequired,
  qty: PropTypes.string,
  onChangeQty: PropTypes.func.isRequired,
  onClickValidate: PropTypes.func.isRequired,
};

SavModuleInstructionsComponent.defaultProps = {
  moduleInstructions: [],
  instructionId: '',
  qty: '1',
};
