import React from 'react';

const context = {
  session: null,
  setSession: () => {},
};

// logout
export const initSession = ({ setSession }) => {
  setSession({
    user: null,
    appDatas: null,
    store: null,
    photos: [],
    sav: { status: 'local' },
  });
};

const SessionContext = React.createContext(context);

export default SessionContext;
