import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClarinsModule from "../../components/ClarinsModule";
import useWorkflow from "../../hooks/useWorkflow";
import useSession from "../../hooks/useSession";

export default function SavModule(props) {
  const { session } = useSession();
  const { store } = session;
  let { goNext } = useWorkflow();
  let modules = store?.meubles[0].modules;

  if (props.storybook) {
    ({ modules, goNext } = props);
  }

  const onClickModule = (module) => {
    goNext({ state: { module } });
  };

  const args = {
    modules,
    onClickModule,
  };
  return <SavModuleComponentTabs {...args} />;
}

const useStyles = makeStyles({
  expanded: {
    maxHeight: "70%",
    overflowY: "scroll",
  },
});

const useStylesSummary = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    minHeight: "30px!important",
    "&.expanded": {
      minHeight: "30px!important",
    },
  },
  content: {
    margin: "0px!important",
  },
}));

export const SavModuleComponentAccordion = ({ modules, onClickModule }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const classesSummary = useStylesSummary();

  const onChangeModuleType = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box height="100%" overflow="hidden">
      {Object.keys(modules).map((key) => (
        <Accordion
          key={key}
          expanded={expanded === key}
          onChange={onChangeModuleType(key)}
          classes={{
            root: classes.root,
            expanded: classes.expanded,
          }}
          //TransitionProps={{ timeout: 0 }}
        >
          <AccordionSummary classes={classesSummary}>{key}</AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-evenly"
            >
              {modules[key].map((module) => (
                <ClarinsModule
                  name={module?.module_client_name}
                  src={module?.module_client_picture}
                  onClick={() => onClickModule(module)}
                  width={124}
                  key={module.module_client_id}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

SavModuleComponentAccordion.propTypes = {
  /**
   * Module list
   */
  modules: PropTypes.object,
  /**
   * Sélection d'un module
   */
  onClickModule: PropTypes.func.isRequired,
};

SavModuleComponentAccordion.defaultProps = {
  modules: {},
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      {...other}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      height="90%"
      style={{ overflowY: "auto" }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SavModuleComponentTabs = ({ modules, onClickModule }) => {
  const [value, setValue] = React.useState(0);
  const [menu, setMenu] = React.useState(true);

  const onChangeTab = (event, newValue) => {
    setMenu(false);
    setValue(newValue);
  };

  const MyButton = React.forwardRef((props, ref) => {
    return (
      <Box my={2}>
        <Button
          style={{ maxWidth: "100%", opacity: 1 }}
          {...props}
          variant="contained"
          color="primary"
          fullWidth
        ></Button>
      </Box>
    );
  });

  return (
    <Box
      height="90%"
      overflow="hidden"
      hidden={!modules || modules.length === 0}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {menu && (
        <AppBar
          position="static"
          color="transparent"
          style={{ boxShadow: "none" }}
        >
          <Toolbar>
            <Box width="100%" color="primary.contrastText">
              <Tabs
                value={value}
                onChange={onChangeTab}
                orientation="vertical"
                textColor="inherit"
                indicatorColor="primary"
              >
                {Object.keys(modules).map((key, index) => (
                  <Tab
                    key={key}
                    label={key}
                    component={MyButton}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Box hidden={menu} height="80%">
        {Object.keys(modules).map((key, index) => (
          <TabPanel value={value} index={index} key={key}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-evenly"
            >
              {modules[key].map((module) => (
                <Box width={124} key={module?.module_client_name}>
                  <ClarinsModule
                    name={module?.module_client_name}
                    src={module?.module_client_picture}
                    onClick={() => onClickModule(module)}
                    key={module.module_client_id}
                    qty={module?.module_client_outOfStock === "1" ? 1 : 0}
                  />
                </Box>
              ))}
            </Box>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};
