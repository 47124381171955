import { useState, useCallback } from 'react';
import Axios from 'axios';
import Stores from '../datas/stores.json';
import StoreDatas from '../datas/store.json';
import AppDatas from '../datas/appDatas.json';
import useSession from '../hooks/useSession';
import useError from '../hooks/useErrors';
import useLog from '../hooks/useLog';

const SLEEP = 0;

function sleeper(ms) {
  return function (x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms));
  };
}

/**
 * https://fr.reactjs.org/docs/hooks-custom.html#extracting-a-custom-hook
 * @param {Object} params
 * @param {string} params.query - Search store query
 */
export default function useCpiReport() {
  const mock = process.env.REACT_APP_MOCK === 'true';
  const [stores, setStores] = useState([]);
  const cpiReportApi = process.env.REACT_APP_CPIREPORT_API;
  const { session, setSession, sessionLogout } = useSession();
  const { user, appDatas, store, sav, photos } = session;
  const { onError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const { log } = useLog('useCpiReport');

  // Auth
  function handleAuth(data, callback, isLogin = false) {
    log('%O %O', handleAuth, arguments);
    setIsLoading(false);
    const user = isLogin ? data?.user : data;
    setSession((prevSess) => ({
      ...prevSess,
      user,
    }));
    if (callback) {
      callback(user);
    }
  }
  function apiGetUserLogged(callback) {
    log('%O', apiGetUserLogged);
    if (mock) {
      handleAuth({
        checkCredentials: true,
        user: 'mock',
      });
    } else {
      setIsLoading(true);
      Axios.post(`${cpiReportApi}user/getUserLogged`, null, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleAuth(response.data, callback))
        .catch((error) => {
          setIsLoading(false);
          onError(error);
        });
    }
  }

  function apiLogin({ login, password, rememberMe }, callback) {
    log('%O %O', apiLogin, arguments);
    if (mock) {
      handleAuth({
        checkCredentials: true,
        user: 'mock',
      });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('user', login);
      formData.append('password', password);
      formData.append('remember_me', rememberMe ? '1' : '0');
      Axios.post(`${cpiReportApi}login`, formData, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleAuth(response.data, callback, true))
        .catch((error) => {
          onError(error);
        });
    }
  }

  function handleLogout(data) {
    log('%O %O', handleLogout, arguments);
    setIsLoading(false);
    sessionLogout();
  }
  const apiLogout = () => {
    log('%O', apiLogout);
    if (mock) {
      handleLogout();
    } else {
      setIsLoading(true);
      Axios.post(`${cpiReportApi}login/logout`, null, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleLogout(response.data))
        .catch((error) => onError(error));
    }
  };

  // AppDatas
  function handleAppDatas(data) {
    log('%O %O', handleAppDatas, arguments);
    setIsLoading(false);
    setSession((prevSess) => ({
      ...prevSess,
      appDatas: data,
    }));
  }

  const getAppData = () => {
    log('%O', getAppData);
    if (mock) {
      setSession((prevSess) => ({
        ...prevSess,
        appDatas: AppDatas,
      }));
    } else {
      setIsLoading(true);
      Axios.post(`${cpiReportApi}salespoint/getInfosClarinsMobile`, null, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleAppDatas(response.data, setIsLoading))
        .catch((error) => {
          onError(error);
        });
    }
  };

  // Search
  function handleStores(data) {
    log('%O %O', handleStores, arguments);
    setIsLoading(false);
    setStores(data);
  }

  function apiGetSalesPoints(query) {
    log('%O %O', apiGetSalesPoints, arguments);
    if (mock) {
      handleStores(Stores);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('query', query);
      Axios.post(`${cpiReportApi}salespoint/getSalesPoints`, formData, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleStores(response.data))
        .catch((error) => onError(error));
    }
  }

  function apiGetSalesPointsByPosition(position) {
    log('%O %O', apiGetSalesPointsByPosition, arguments);
    if (mock) {
      handleStores(Stores);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('latitude', position.coords.latitude);
      formData.append('longitude', position.coords.longitude);
      Axios.post(
        `${cpiReportApi}salespoint/getSalesPointsByPosition`,
        formData,
        {
          withCredentials: true,
        }
      )
        .then(sleeper(SLEEP))
        .then((response) => handleStores(response.data))
        .catch((error) => onError(error));
    }
  }

  // Store
  function handleStore(data) {
    log('%O %O', handleStore, arguments);
    setIsLoading(false);
    setSession((prevSess) => ({
      ...prevSess,
      store: data,
    }));
  }

  function apiGetSalesPointClarinsByID(id_mag) {
    log('%O %O', apiGetSalesPointClarinsByID, arguments);
    if (mock) {
      handleStore(StoreDatas);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id_mag', id_mag);
      Axios.post(
        `${cpiReportApi}salespoint/getSalesPointClarinsByID`,
        formData,
        {
          withCredentials: true,
        }
      )
        .then(sleeper(SLEEP))
        .then((response) => handleStore(response.data, setIsLoading))
        .catch((error) => onError(error));
    }
  }

  // Send planogramme mail
  function handleSendPlanoByEmail(data) {
    log('%O %O', handleSendPlanoByEmail, arguments);
    setIsLoading(false);
  }

  function apiSendPlanoByEmail(id_mag, email, updateEmail) {
    log('%O %O', apiSendPlanoByEmail, arguments);
    if (mock) {
      handleSendPlanoByEmail();
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id_mag', id_mag);
      formData.append('email', email);
      formData.append('updateEmail', updateEmail);
      Axios.post(`${cpiReportApi}salespoint/sendPlanoByEmail`, formData, {
        withCredentials: true,
      })
        .then(sleeper(SLEEP))
        .then((response) => handleSendPlanoByEmail(response.data))
        .catch((error) => onError(error));
    }
  }

  // Send SAV
  function handleSav(data) {
    log('%O %O', handleSav, arguments);
    setIsLoading(false);
    if (data.status === 0) {
      const cloneSav = { ...sav };
      cloneSav.status = 'server';
      setSession((prevSess) => ({
        ...prevSess,
        sav: cloneSav,
      }));
      apiGetSalesPointClarinsByID(store.id_mag); // refresh store data
    }
    // TODO: error management
  }

  const sendSav = () => {
    setIsLoading(true);
    const cloneSav = { ...sav };
    log('%O %O', sendSav, cloneSav);
    const cloneSavString = JSON.stringify(cloneSav);
    const formData = new FormData();
    formData.append('report', cloneSavString);
    photos.forEach((photo) => formData.append('photos[]', photo));

    Axios.post(`${cpiReportApi}report/addReportClarins`, formData, {
      withCredentials: true,
    })
      .then(sleeper(SLEEP))
      .then((response) => handleSav(response.data))
      .catch((error) => onError(error));
  };

  return {
    user,
    apiGetUserLogged: useCallback(apiGetUserLogged, []),
    apiLogin,
    apiLogout: useCallback(apiLogout, []),
    appDatas,
    getAppData: useCallback(getAppData, []),
    apiGetSalesPoints,
    apiGetSalesPointsByPosition,
    stores,
    store,
    apiGetSalesPointClarinsByID: useCallback(apiGetSalesPointClarinsByID, []),
    sendSav,
    apiSendPlanoByEmail,
    isLoading,
  };
}
