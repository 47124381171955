import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/fr';

export default function ClarinsDate({ date }) {
  moment.locale('fr');
  const m = moment(date);
  if (date && m.isValid()) {
    return <Box>{m.format('L')}</Box>;
  } else {
    return null;
  }
}
