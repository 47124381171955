import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import useSav from '../../hooks/useSav';
import useWorkflow from '../../hooks/useWorkflow';
import ClarinsPhoto from '../../components/ClarinsPhoto';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));

const UploadButton = ({ fileInputRef, onChangeFileInput }) => {
  // file in fileInputRef.current.files
  const classes = useStyles();

  return (
    <Box>
      <input
        className={classes.input}
        accept="image/*"
        id="contained-button-file"
        type="file"
        ref={fileInputRef}
        onChange={onChangeFileInput}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          <PublishIcon />
        </Button>
      </label>
    </Box>
  );
};

export default function SavStructurel(props) {
  const [dataUri, setDataUri] = useState('');
  const [structurelTypeId, setStructurelTypeId] = useState('');
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  let { sav, structurelTypes, addTask, addPhoto } = useSav();
  let { goNext } = useWorkflow();
  const fileInputRef = useRef(null);

  if (props.storybook) {
    ({ sav, structurelTypes, addTask, addPhoto, goNext } = props);
  }

  const onChangeStructurelType = (event) => {
    setStructurelTypeId(event.target.value);
  };

  const onChangeComment = (event) => {
    setComment(event.target.value);
  };

  const onChangeFileInput = () => {
    if (fileInputRef.current.files.length > 0) {
      const file = fileInputRef.current.files[0];
      const reader = new FileReader();
      reader.onload = () => setDataUri(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const createTask = () => {
    const detail = structurelTypes?.find(
      (t) => t?.structurel_sav_type_id === structurelTypeId
    );
    const instruction = { comment };
    if (detail) {
      addPhoto(dataUri);
      addTask({ detail, instruction });
      goNext();
    } else {
      // TODO: display error
      console.error('Invalid task detail');
      setError(true);
    }
  };
  const args = {
    onChangeStructurelType,
    structurelTypes,
    structurelTypeId,
    comment,
    onChangeComment,
    savTaskType: sav?.taskType,
    dataUri,
    setDataUri,
    createTask,
    fileInputRef,
    onChangeFileInput,
    error,
  };
  return <SavStructurelComponent {...args} />;
}

export const SavStructurelComponent = ({
  structurelTypes,
  structurelTypeId,
  onChangeStructurelType,
  comment,
  onChangeComment,
  savTaskType,
  dataUri,
  setDataUri,
  createTask,
  fileInputRef,
  onChangeFileInput,
  error,
}) => {
  const onDeletePhoto = React.useCallback(() => {
    setDataUri('');
  }, [setDataUri]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ overflowY: 'auto' }}
      justifyContent="flex-start"
      alignItems="center"
      height="90%"
    >
      <FormControl fullWidth error={error}>
        <InputLabel>Type de SAV Structurel</InputLabel>
        <Select value={structurelTypeId} onChange={onChangeStructurelType}>
          {structurelTypes
            ?.filter(
              (t) => t.task_type_detail === savTaskType?.task_type_detail
            )
            ?.map((t) => (
              <MenuItem
                key={t?.structurel_sav_type_id}
                value={t?.structurel_sav_type_id}
              >
                {t?.structurel_sav_type_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        label="Commentaire"
        multiline
        rows={2}
        value={comment}
        onChange={onChangeComment}
        fullWidth
      />
      <Box
        flexDirection="row"
        display="flex"
        justifyContent="space-around"
        width="100%"
        m={2}
      >
        <UploadButton
          fileInputRef={fileInputRef}
          onChangeFileInput={onChangeFileInput}
        />
      </Box>
      <ClarinsPhoto
        my={1}
        width="100%"
        height="50%"
        dataUri={dataUri}
        setDataUri={setDataUri}
        onDelete={onDeletePhoto}
      />
      <Button variant="contained" color="primary" onClick={createTask}>
        VALIDER
      </Button>
    </Box>
  );
};

SavStructurelComponent.propTypes = {
  structurelTypes: PropTypes.array,
  structurelTypeId: PropTypes.string.isRequired,
  onChangeStructurelType: PropTypes.func.isRequired,
  comment: PropTypes.string,
  onChangeComment: PropTypes.func.isRequired,
  savTaskType: PropTypes.object.isRequired,
  dataUri: PropTypes.string,
  setDataUri: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
};

SavStructurelComponent.defaultProps = {
  structurelTypes: [],
  structurelTypeId: '',
  comment: '',
  dataUri: '',
};
