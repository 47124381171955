import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useCpiReport from "../../../hooks/useCpiReport";
import styles from "./Carousel.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px",
    background: theme.palette.primary.light,
    "& .slick-prev:before,.slick-next:before": {
      color: theme.palette.primary.main,
    },
    "& .slick-dots button:before": {
      color: theme.palette.primary.main,
    },
    "& .slick-dots .slick-active button:before": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Carousel(props) {
  let { store } = useCpiReport();
  if (props.storybook) {
    ({ store } = props);
  }
  return <CarouselComponent photos={store?.photos} />;
}

export const CarouselComponent = ({ photos }) => {
  const classes = useStyles();
  const settings = {
    dots: true,
  };
  if (photos && photos.length > 0) {
    return (
      <Box className={classes.root} height="100%">
        <Slider {...settings}>
          {photos.map((path) => {
            return (
              <Box key={path} className={styles.imgBox}>
                <img className={styles.img} src={path} alt="" />
              </Box>
            );
          })}
        </Slider>
      </Box>
    );
  } else {
    return null;
  }
};

CarouselComponent.propTypes = {
  /**
   * Tableau de photos
   */
  photos: PropTypes.array,
};

CarouselComponent.defaultProps = {
  photos: [],
};
