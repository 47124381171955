import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import useWorkflow from '../../hooks/useWorkflow';
import useSession from '../../hooks/useSession';

export default function SavLayout({ children }) {
  const { session } = useSession();
  const { store, sav } = session;
  const { state } = useWorkflow();

  return (
    <Box height="100%" fontSize="caption.fontSize">
      <Box mb={1} height="10%">
        <Breadcrumbs>
          {store?.nom_mag && (
            <Link color="inherit">
              <Typography variant="caption">{store?.nom_mag}</Typography>
            </Link>
          )}
          {sav?.interventionType?.intervention_type_name && (
            <Link color="inherit">
              <Typography variant="caption">
                {sav?.interventionType?.intervention_type_name}
              </Typography>
            </Link>
          )}
          {state?.module?.module_client_name && (
            <Link color="inherit">
              <Typography variant="caption">
                {state?.module?.module_client_name}
              </Typography>
            </Link>
          )}
        </Breadcrumbs>
      </Box>
      {children}
    </Box>
  );
}
