import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import useWorkflow from '../../hooks/useWorkflow';
import ClarinsDate from '../../components/ClarinsDate';
import useSession from '../../hooks/useSession';

export default function InterventionsList(props) {
  let { session } = useSession();
  let { goNext } = useWorkflow();

  const onClickIntervention = (id) => {
    goNext({ id });
  };

  if (props.storybook) {
    ({ session, goNext } = props);
  }

  const args = {
    interventions: session?.store?.interventions,
    onClickIntervention,
  };
  return <InterventionsListComponent {...args}></InterventionsListComponent>;
}

export const InterventionsListComponent = ({
  interventions,
  onClickIntervention,
}) => {
  return (
    <Box maxHeight="100%" style={{ overflowY: 'auto' }}>
      {interventions?.map((intervention) => (
        <Box
          my={1}
          key={intervention?.install_id}
          onClick={() => onClickIntervention(intervention?.install_id)}
          height={80}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box fontFamily="GothamMedium">
                {intervention?.intervention_type_name}
              </Box>
              <Box>
                <ClarinsDate date={intervention?.date_created} />
              </Box>
            </Box>
            <Box>{intervention?.status}</Box>
          </Box>
          <Divider></Divider>
        </Box>
      ))}
    </Box>
  );
};

InterventionsListComponent.propTypes = {
  /**
   * Liste des interventions
   */
  interventions: PropTypes.array,
  /**
   * Sélection d'une intervention
   */
  onClickIntervention: PropTypes.func.isRequired,
};

InterventionsListComponent.defaultProps = {
  interventions: [],
};
