import React from "react";
import { Box, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClarinsImage from "../ClarinsImage";

const useStyles = makeStyles({
  module_thumb: {
    width: "100px",
    height: "90px",
    objectFit: "contain",
  },
  badgeStyleVisible: {
    "& .MuiBadge-badge": {
      transform: `scale(1)`,
    },
  },
  badgeStyleInvisible: {
    "& .MuiBadge-badge": {
      transform: `scale(0)`,
    },
  },
});

export default function ClarinsModule({ src, name, qty, ...props }) {
  const classes = useStyles();
  return (
    <Badge
      badgeContent={`Out of stock`}
      color="primary"
      overlap="circle"
      invisible={!qty}
      style={{ width: "100%" }}
      className={!qty ? classes.badgeStyleInvisible : classes.badgeStyleVisible}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={1}
        my={1}
        border={1}
        borderColor="primary.light"
        width="100%"
        {...props}
      >
        <ClarinsImage
          className={classes.module_thumb}
          src={src}
          alt=""
        ></ClarinsImage>
        <Box fontSize={12} pt={1} textAlign="center">
          {name}
        </Box>
      </Box>
    </Badge>
  );
}
