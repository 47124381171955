import React from 'react';
import { Box } from '@material-ui/core';

export default function ClarinsTextField({ label, value, children }) {
  return (
    <Box>
      <Box fontSize="caption.fontSize">{label}</Box>
      {!children && <Box fontFamily="GothamMedium">{value || '\u00A0'} </Box>}
      {children && <Box fontFamily="GothamMedium">{children}</Box>}
    </Box>
  );
}
